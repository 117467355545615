import { getDeviceList_returns_data_item } from "../getDeviceList";

export const HardCodedDevices: getDeviceList_returns_data_item[] = [
  {
    lat: "22.5690000",
    location: "One 10, New Town",
    long: "88.4627830",
    dev_id: "4142688", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 316,
    district: "Kolkata",
    aqi: "0",
  },
  {
    lat: "22.5836610",
    location: "Vaanya Appartment", // Himadri AQMS 2
    long: "88.4954810",
    dev_id: "4142604", // "0b44f0afd06696ad337a8d5338f1dcdc",
    loc_id: 313,
    district: "North 24 Pgs.",
    aqi: "0",
  },
];
